<script>
import api from '@/command/api'
import DetailFormGroup from '@/components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '@/command/netTool'

export default {
  name: 'gateMachineManageDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {},
    }
  },
  mounted() {
    const { id } = this.$route.query
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/farmTicketGate/detail?id=${id}`,
        })
        .then((res) => {
          this.detail = {
            ...res,
          }
          this.$forceUpdate()
        })
    }
  },
  methods: {
    /** end 数据查询 */

    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称',
            type: 'input',
            cols: 12,
            key: 'gateName',
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '编号',
            type: 'input',
            cols: 12,
            key: 'deviceNo',
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: 'IP地址',
            type: 'input',
            cols: 12,
            key: 'ipAddress',
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '通道类型',
            type: 'radioButton',
            cols: 12,
            key: 'gateType',
            typeData: [
              {
                name: '检票通道',
                value: '0',
              },
              {
                name: '进口上报',
                value: '1',
              },
              {
                name: '出口上报',
                value: '2',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '检票提示语',
            type: 'input',
            cols: 12,
            key: 'prompt',
          },
          {
            name: '是否统计在园人数',
            type: 'radioButton',
            cols: 12,
            key: 'isCount',
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
          },
          {
            key: 'remark',
            align: 'left',
            name: '备注',
            type: 'textArea',
            cols: 12,
            maxLength: 150,
            minHeight: '100px',
            showCount: true,
          },
        ],
      }
    },
    //#endregion 基础设置
    /** end 数据查询 */

    getForm2() {
      return {
        title: '其他信息',
        type: 'cardForm',
        data: [
          {
            name: '绑定管理员',
            key: 'name2',
            type: 'select',
            cols: 12,
            props: {
              mode: 'multiple',
              filterOption: (input, option) =>
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0,
            },
            typeData: [
              {
                name: '管理员1',
                value: '0',
              },
              {
                name: '管理员2',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '登录密码(创建时若密码为空，将设置为默认密码：888888)',
            type: 'input',
            cols: 12,
            key: 'name',
            placeholder: '请输入登录密码',
          },
        ],
      }
    },
    //#endregion 其他信息
    /** end 方法 */

    getFoot() {
      let that = this
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          display: true,
          onClick: (data) => {
            let obj = Object.assign({
              ...data,
            })
            console.log('sumbit', obj)
            // return
            api.command[data.id ? 'edit' : 'create']
              .call(this, {
                url: '/farmTicketGate',
                params: obj,
                isPost: false,
              })
              .then((result) => {
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back(),
          display: true,
        },
      ].filter((e) => e.display)
      return {
        left,
      }
    },
    renderTab() {
      return [this.getForm1()]
    },
  },
  render() {
    return (
      <div>
        <DetailFormGroup foot={this.getFoot()} form={this.detail} data={this.renderTab()} />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/ .detail-form-group {
  height: calc(100vh - 175px);
}
</style>
